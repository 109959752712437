import React, { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('../views/Dashboard')));
const StaffDashboard = Loadable(lazy(() => import('../views/StaffDashboard')));
const Login = Loadable(lazy(() => import('../views/Login')));

const UtilsTypography = Loadable(lazy(() => import('../views/Utils/Typography')));

const SamplePage = Loadable(lazy(() => import('../views/SamplePage')));
const SchoolSetup = Loadable(lazy(() => import('../views/Setup/SchoolSetup')));
const StaffSetup = Loadable(lazy(() => import('../views/Setup/StaffSetup')));
const StudentSetup = Loadable(lazy(() => import('../views/Setup/StudentSetup')));
const Sessions = Loadable(lazy(() => import('../views/Management/Sessions')))
const Terms = Loadable(lazy(() => import('../views/Management/Terms')))
const Subjects = Loadable(lazy(() => import('../views/Management/Subjects')))
const Classes = Loadable(lazy(() => import('../views/Management/Classes')))
const CreateResults = Loadable(lazy(() => import('../views/Results/CreateResults')))
const ViewResults = Loadable(lazy(() => import('../views/Results/ViewResults')))
const Holidays = Loadable(lazy(() => import('../views/Attendance/Holidays')))
const LateTime = Loadable(lazy(() => import('../views/Attendance/LateTime')))
const ListAttendance = Loadable(lazy(() => import('../views/Attendance/ListAttendance')))
const SingleResult = Loadable(lazy(() => import('../views/Results/SingleResult')))
const CoCurriculum = Loadable(lazy(() => import('../views/Management/CoCurriculum')))

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: '/dashboard/staff',
      element: <StaffDashboard />
    },
    {
      path: '/dashboard/default',
      element: <DashboardDefault />
    },
    { path: '/setup/school', element: <SchoolSetup /> },
    { path: '/setup/staff', element: <StaffSetup /> },
    { path: '/setup/students', element: <StudentSetup /> },
    { path: '/sessions', element: <Sessions /> },
    { path: '/terms', element: <Terms /> },
    { path: '/subjects', element: <Subjects /> },
    { path: '/classes', element: <Classes /> },
    { path: '/create-results', element: <CreateResults /> },
    { path: '/view-results', element: <ViewResults /> },
    { path: '/holidays', element: <Holidays /> },
    { path: '/late-time', element: <LateTime /> },
    { path: '/attendance-setup', element: <Holidays /> },
    { path: '/list-attendance', element: <ListAttendance /> },
    { path: '/single-result', element: <SingleResult /> },
    { path: '/co-curriculum', element: <CoCurriculum /> }
  ]
};

export default MainRoutes;
