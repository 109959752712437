import axios from 'axios';

export const gridSpacing = 3;
export const drawerWidth = 280;

// ==============================|| THEME CONFIG  ||============================== //

export const axiosInstance = axios.create({
    baseURL: 'https://api.msb.ac.tz/core-api/',
})
