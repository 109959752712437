// assets
import NavigationOutlinedIcon from '@mui/icons-material/NavigationOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PreviewIcon from '@mui/icons-material/Preview';
import TaskIcon from '@mui/icons-material/Task';
import HistoryIcon from '@mui/icons-material/History';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SubjectIcon from '@mui/icons-material/Subject';
import ClassIcon from '@mui/icons-material/Class';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const icons = {
  NavigationOutlinedIcon: NavigationOutlinedIcon,
  HomeOutlinedIcon: HomeOutlinedIcon,
  ChromeReaderModeOutlinedIcon: ChromeReaderModeOutlinedIcon,
  HelpOutlineOutlinedIcon: HelpOutlineOutlinedIcon,
  SecurityOutlinedIcon: SecurityOutlinedIcon,
  AccountTreeOutlinedIcon: AccountTreeOutlinedIcon,
  BlockOutlinedIcon: BlockOutlinedIcon,
  AppsOutlinedIcon: AppsOutlinedIcon,
  ContactSupportOutlinedIcon: ContactSupportOutlinedIcon,
  AnalyticsIcon: AnalyticsIcon,
  AccountBalanceIcon: AccountBalanceIcon,
  GroupIcon: GroupIcon,
  SchoolIcon: SchoolIcon,
  SettingsIcon: SettingsIcon,
  ArticleIcon: ArticleIcon,
  BorderColorIcon: BorderColorIcon,
  PreviewIcon: PreviewIcon,
  TaskIcon: TaskIcon,
  HistoryIcon: HistoryIcon,
  ManageAccountsIcon: ManageAccountsIcon,
  CalendarMonthIcon: CalendarMonthIcon,
  DateRangeIcon: DateRangeIcon,
  SubjectIcon: SubjectIcon,
  ClassIcon: ClassIcon,
  UpcomingIcon: UpcomingIcon,
  WatchLaterIcon: WatchLaterIcon
};

// eslint-disable-next-line
export default {
  items: [
    {
      id: 'navigation',
      title: 'Dashboard',
      type: 'group',
      icon: icons['AnalyticsIcon'],
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: icons['AnalyticsIcon'],
          url: '/dashboard/default'
        },
        {
          id: 'staff_dashboard',
          title: 'Staff Dashboard',
          type: 'item',
          icon: icons['AnalyticsIcon'],
          url: '/dashboard/staff'
        }
      ]
    },
    {
      id: 'main',
      title: 'Main',
      type: 'group',
      icon: icons['NavigationOutlinedIcon'],
      children: [
        // {
        //   id: 'setup',
        //   title: 'Setup',
        //   type: 'collapse',
        //   icon: icons['SettingsIcon'],
        //   children: [
        //     {
        //       id: 'school',
        //       title: 'School Setup',
        //       type: 'item',
        //       url: '/setup/school',
        //       icon: icons['AccountBalanceIcon']
        //     },
        //     {
        //       id: 'staff',
        //       title: 'Staff Setup',
        //       type: 'item',
        //       url: '/setup/staff',
        //       icon: icons['GroupIcon']
        //     },
        //     {
        //       id: 'student',
        //       title: 'Student Setup',
        //       type: 'item',
        //       url: '/setup/students',
        //       icon: icons['SchoolIcon']
        //     },
        //   ]
        // },
        // {
        //   id: 'results',
        //   title: 'Results',
        //   type: 'collapse',
        //   icon: icons['TaskIcon'],
        //   children: [
        //     {
        //       id: 'create',
        //       title: 'Create Results',
        //       type: 'item',
        //       url: '/create-results',
        //       icon: icons['ArticleIcon']
        //     },
        //     {
        //       id: 'update',
        //       title: 'Update Results',
        //       type: 'item',
        //       url: '/update-results',
        //       icon: icons['BorderColorIcon']
        //     },
        //     {
        //       id: 'view',
        //       title: 'View Results',
        //       type: 'item',
        //       url: '/view-results',
        //       icon: icons['PreviewIcon']
        //     },
        //   ]
        // },
        {
          id: 'attendance',
          title: 'Attendance',
          type: 'collapse',
          icon: icons['WatchLaterIcon'],
          children: [
            {
              id: 'attendanceSetup',
              title: 'Setup',
              type: 'item',
              url: '/attendance-setup',
              icon: icons['UpcomingIcon']
            },
            {
              id: 'listAttendance',
              title: 'List Attendance',
              type: 'item',
              url: '/list-attendance',
              icon: icons['HistoryIcon']
            },
            {
              id: 'student',
              title: 'Student List',
              type: 'item',
              url: '/setup/students',
              icon: icons['SchoolIcon']
            },
            {
              id: 'classes',
              title: 'Classes',
              type: 'item',
              url: '/classes',
              icon: icons['ClassIcon']
            },
          ]
        },
      ]
    },

    // {
    //   id: 'management',
    //   title: 'Management',
    //   type: 'group',
    //   icon: icons['NavigationOutlinedIcon'],
    //   children: [
    //     {
    //       id: 'sub_management',
    //       title: 'Management',
    //       type: 'collapse',
    //       icon: icons['ManageAccountsIcon'],
    //       children: [
    //         {
    //           id: 'sessions',
    //           title: 'Sessions',
    //           type: 'item',
    //           url: '/sessions',
    //           icon: icons['CalendarMonthIcon']
    //         },
    //         {
    //           id: 'terms',
    //           title: 'Terms',
    //           type: 'item',
    //           url: '/terms',
    //           icon: icons['DateRangeIcon']
    //         },
    //         {
    //           id: 'subjectss',
    //           title: 'Subjects',
    //           type: 'item',
    //           url: '/subjects',
    //           icon: icons['SubjectIcon']
    //         },
    //         {
    //           id: 'co-curriculum',
    //           title: 'Co Curriculum Activities',
    //           type: 'item',
    //           url: '/co-curriculum',
    //           icon: icons['SubjectIcon']
    //         },
    //         {
    //           id: 'classes',
    //           title: 'Classes',
    //           type: 'item',
    //           url: '/classes',
    //           icon: icons['ClassIcon']
    //         },
    //       ]
    //     }
    //   ]
    // },
  ]
};
